<template>
  <li v-if="item && item.isVisible" :key="item._uid"
      class="block relative leading-sm" :class="!item.icon_only ? 'md:pl-5 md:pr-2' : 'icon-only'"
      :data-id="item.code || item.icon">
    <component
      :is="item.isExternal ? 'a' : 'router-link'"
      :href="item.card_link.cached_url"
      :to="localizedRoute(item.card_link.cached_url)"
      :target="item.target"
      class="flex text-h5 text-grey-15 md:font-bold uppercase leading-loose tracking-lg hover:text-white py-3 md:py-md relative font-normal"
      :class="{ 'md:pl-16' : item.icon && !item.icon_only }"
    >
      <span v-if="item.icon"
            class="brand-icon md:block hidden left-0"
            :class="(!item.icon_only ? 'absolute ' : 'm-auto ') + item.icon">
        <i class="text-2h6 block"></i>
      </span>
      <span class="flex-1 text-h6 md:text-h5" :class="{'md:sr-only': item.icon_only}">
        <span >{{ item.name }}</span>
      </span>
    </component>
  </li>
</template>
<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  extends: Blok,
  name: 'HeaderMenuDropdownLink'
}

</script>
<style lang="scss" scoped>
@media screen and (min-width: theme('screens.md')) {
  .icon-only {
    min-height: 3rem;

  }
  .icon-only:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }

  .icon-only:hover {
    background: rgba(143, 123, 76, 1);
  }
}

.brand-icon {
  &.icon-lbp {
    font-size: 32px;
    top: 6px;
    left: 16px;
  }

  &.icon-rco {
    @apply top-10px;
    font-size: 21px;
    left: 7px;
  }

  &.icon-rco-bleu {
    font-size: 25px;
  }

  &.icon-rco-color {
    font-size: 14px;
  }

  &.icon-v76 {
    font-size: 30px;
    top: 2px;
    left: 7px;
  }

  &.icon-sc {
    top: 7px;
    left: -10px;
    font-size: 29px;
  }

  &.icon-phorest {
    @apply opacity-70 bg-no-repeat bg-center bg-contain;
    top: 9px;
    left: 5px;
    width: 32px;
    height: 35px;
    background-image: url('/assets/phorest.png');
  }
}

</style>
